import { Color, TypographyColor } from "../components/typography"
import ImageEditor, { AspectRatio } from "../Image"
import Typography, { TypographyElement } from "../Typography"

import React from "react"
import { types } from "react-bricks"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"

export interface ImageTextColumnItemProps {
    index?: number
    text: TypographyElement[]
    color?: TypographyColor
}

const ImageTextColumnItem: types.Brick<ImageTextColumnItemProps> = ({ color, index, text }) => {
    const emptyText = useCheckForEmptyText(text)

    return (
        <li key={index} className="flex flex-col justify-self-center">
            <ImageEditor
                aspectRatio={AspectRatio["4/5"]}
                maxWidth={345}
                className="mb-5 hidden overflow-hidden rounded-base sm:block"
                propName="largeImage"
            />
            <ImageEditor
                aspectRatio={AspectRatio["1/1"]}
                maxWidth={345}
                className="mb-3.5 block overflow-hidden rounded-base sm:hidden"
                propName="smallImage"
            />
            {!emptyText && (
                <Color color={color}>
                    <Typography block="image-text-column" allowList={[]} />
                </Color>
            )}
        </li>
    )
}

ImageTextColumnItem.schema = {
    name: "ImageTextColumnItem",
    label: "Image Text Column Item",
    hideFromAddMenu: true,
    getDefaultProps: (): ImageTextColumnItemProps => ({
        text: [
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    },
                ],
            },
        ],
    }),
}

export default ImageTextColumnItem
